import React from 'react';

export const MaximizeMenuIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11314 1.80005H9.6998C9.97595 1.80005 10.1998 2.02391 10.1998 2.30005V9.70005C10.1998 9.97619 9.97595 10.2 9.6998 10.2H5.11314V1.80005ZM4.11314 1.80005H2.2998C2.02366 1.80005 1.7998 2.02391 1.7998 2.30005V9.70005C1.7998 9.97619 2.02366 10.2 2.29981 10.2H4.11314V1.80005ZM0.799805 2.30005C0.799805 1.47162 1.47138 0.800049 2.2998 0.800049H9.6998C10.5282 0.800049 11.1998 1.47162 11.1998 2.30005V9.70005C11.1998 10.5285 10.5282 11.2 9.6998 11.2H2.29981C1.47138 11.2 0.799805 10.5285 0.799805 9.70005V2.30005Z"
      fill="currentColor"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45817 7.89943C6.25672 7.71057 6.24651 7.39415 6.43538 7.1927L7.58417 5.96732L6.43538 4.74195C6.24651 4.54049 6.25672 4.22407 6.45817 4.03521C6.65963 3.84634 6.97605 3.85655 7.16491 4.058L8.6343 5.62535C8.81461 5.81768 8.81461 6.11696 8.6343 6.30929L7.16491 7.87664C6.97605 8.07809 6.65963 8.0883 6.45817 7.89943Z"
      fill="currentColor"
    />
  </svg>
);
