import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import { GanttChart } from '../../components/gantt-chart/gantt-chart';
import { StepGanttHeader } from '../../routes/project/components/step-gantt-header';
import { bootstrapProject } from '../../features/projects/store/projects.slice';
import { PageLoader } from '../../app/components/page-loader';
import { Space } from '../../app/entities/space';
import { useCurrentMember } from '../../hooks/use-current-member';
import placeholder from '../../assets/images/project_cover_placeholder.jpg';
import { StageNumber } from '../../features/process/components/stage-header';
import { Header } from '../../app/components/header';
import { getDone } from '../../lib/utils/math';

export const MultiProjectsCalendarPage = observer(() => {
  const { spaceId } = useParams() as { spaceId: string; projectId: string };

  const currentMember = useCurrentMember();

  const isViewOnly = currentMember?.role === 'guest';
  const [isLoading, setIsLoading] = useState(true);

  const space = Space.getOne(spaceId);
  const spaceProjects = space?.projects;

  useEffect(() => {
    const promises: Promise<void>[] = [];

    spaceProjects?.forEach((project) => {
      promises.push(bootstrapProject(project._id));
    });

    const fetchData = async () => {
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [spaceId, spaceProjects]);

  const ganttItems: React.ComponentProps<typeof GanttChart>['items'] | undefined =
    spaceProjects?.map((project, projectIndex) => {
      // TODO: get from project entity
      const { sum, done } = getDone(project.stages);

      return {
        id: project._id,
        name: project.name,
        isCollapsed: projectIndex !== 0,
        headerSuffix: <ProjectImage src={project.avatar || placeholder} alt={project.name} />,
        ...(done && { completed: done }),
        ...(sum && { total: sum }),
        subItems: project?.stages.map((stage, stagesIndex) => ({
          id: stage._id,
          name: stage.name,
          nameSuffix: (
            <StageNumber isActive>{(stagesIndex + 1).toString().padStart(2, '0')}</StageNumber>
          ),
          ...(stage.startDate && { startDate: new Date(stage.startDate) }),
          ...(stage.dueDate && { dueDate: new Date(stage.dueDate) }),
          ...(stage.completedSteps && { completed: stage.completedSteps }),
          ...(stage.totalSteps && { total: stage.totalSteps }),
          isCollapsed: true,

          subItems:
            stage.steps?.map((step) => ({
              id: step._id,
              name: step.displayName,
              onItemUpdate: (values: { dueDate?: Date; startDate?: Date }) => {
                const startDate =
                  (values.startDate && DateTime.fromJSDate(values.startDate).toISO()) || undefined;
                const dueDate =
                  (values.dueDate && DateTime.fromJSDate(values.dueDate).toISO()) || undefined;

                if (startDate || dueDate) {
                  step.update({
                    ...(startDate && { startDate }),
                    ...(dueDate && { dueDate }),
                  });
                }
              },
              isDone: step.done,
              sideHeader: <StepGanttHeader step={step} isViewOnly={isViewOnly} />,
              ...(step.startDate && { startDate: new Date(step.startDate) }),
              ...(step.dueDate && { dueDate: new Date(step.dueDate) }),
            })) || [],
        })),
      };
    });

  return (
    <Page>
      <Header title={'Calendar'}>
        <div />
      </Header>

      <Container>
        {isLoading ? (
          <PageLoader />
        ) : (
          ganttItems && (
            <GanttChart
              items={ganttItems}
              primaryColor="var(--color-primary-sandal)"
              secondaryColor="var(--color-actions-main-action)"
            />
          )
        )}
      </Container>
    </Page>
  );
});

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ProjectImage = styled.img`
  height: 2.4rem;
  width: 3.6rem;
  min-width: 3.6rem;
  flex: 1;
  object-fit: cover;
  border-radius: 0.4rem;
`;
