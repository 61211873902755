import React, { useMemo } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { PersonIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';

import { DropdownMenu } from '../modals/dropdown-menu/DropdownMenu';
import { Project } from '../../app/entities/project';

type AssigneeSelectorProps = {
  collaborators: Project['collaborators'];
  assigneeId?: string | null;
  onCheckedChange?: (assigneeId?: string | null) => void;
};

export const AssigneeSelector: React.FC<AssigneeSelectorProps> = observer(
  ({ collaborators, assigneeId, onCheckedChange }) => {
    const items = [
      {
        onCheckedChange: () => {
          onCheckedChange?.(null);
        },
        title: 'No assignee',
        isChecked: !assigneeId,
        icon: (
          <Placeholder>
            <PersonIcon />
          </Placeholder>
        ),
      },
    ];

    collaborators.forEach((collaborator) => {
      items.push({
        onCheckedChange: () => {
          onCheckedChange?.(collaborator.member?._id);
        },
        title: collaborator.fullName,
        isChecked: assigneeId ? assigneeId === collaborator.member?._id : false,
        icon: (
          <CustomAvatar>
            <Avatar
              size="small"
              initials={collaborator.initials}
              src={collaborator.member?.avatar}
            />
          </CustomAvatar>
        ),
      });
    });

    const assignee = useMemo(
      () =>
        assigneeId &&
        collaborators?.find((collaborator) => collaborator.member?._id === assigneeId),
      [assigneeId, collaborators],
    );

    return (
      <DropdownMenu
        items={items}
        trigger={
          assignee ? (
            <CustomAvatar>
              <Avatar size="small" initials={assignee.initials} src={assignee.avatar} />
            </CustomAvatar>
          ) : (
            <Placeholder>
              <PersonIcon />
            </Placeholder>
          )
        }
      />
    );
  },
);

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;
  border-radius: var(--border-radius-small);
  border: 1px var(--color-grayscale-light-slate) dashed;
  color: var(--color-grayscale-light-slate);

  &:hover {
    cursor: pointer;
    color: var(--color-texts-high-contrast);
    border-color: var(--color-texts-high-contrast);
  }
`;

const CustomAvatar = styled.div`
  > span > span {
    background: var(--bg-elevation-3);
  }
`;
