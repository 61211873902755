import React from 'react';

export const MinimizeMenuIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11332 1.80005H9.69999C9.97613 1.80005 10.2 2.02391 10.2 2.30005V9.70005C10.2 9.97619 9.97613 10.2 9.69999 10.2H5.11332V1.80005ZM4.11332 1.80005H2.29999C2.02385 1.80005 1.79999 2.02391 1.79999 2.30005V9.70005C1.79999 9.97619 2.02385 10.2 2.29999 10.2H4.11332V1.80005ZM0.799988 2.30005C0.799988 1.47162 1.47156 0.800049 2.29999 0.800049H9.69999C10.5284 0.800049 11.2 1.47162 11.2 2.30005V9.70005C11.2 10.5285 10.5284 11.2 9.69999 11.2H2.29999C1.47156 11.2 0.799988 10.5285 0.799988 9.70005V2.30005Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.61134 4.03526C8.8128 4.22412 8.82301 4.54054 8.63414 4.742L7.48535 5.96737L8.63414 7.19275C8.82301 7.3942 8.8128 7.71062 8.61134 7.89949C8.40989 8.08835 8.09347 8.07814 7.90461 7.87669L6.43522 6.30934C6.25491 6.11701 6.25491 5.81773 6.43522 5.6254L7.90461 4.05806C8.09347 3.8566 8.40989 3.84639 8.61134 4.03526Z"
      fill="currentColor"
    />
  </svg>
);
