import React from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { observer } from 'mobx-react-lite';

import { Header } from './project.page';
import { Location } from '../../app/entities/location';
import { FolderView } from 'features/assets/components/folder-view';
import { Asset } from 'app/entities/asset';
import { AssetViewerScreen } from '../../features/assets/screens/asset-viewer.screen';

export const ProjectLocationAssetRoute: React.FC = observer(() => {
  const { locationId, assetId } = useParams() as { locationId: string; assetId: string };

  const location = Location.getOne(locationId)!;
  const asset = Asset.getOne(assetId)!;

  return (
    <>
      <Header>
        <Crumb>
          <Parent to="../">Locations</Parent>
          <ChevronRightIcon />
          <Parent to="../">{location.name}</Parent>
          <ChevronRightIcon />
          {asset.name}
        </Crumb>
      </Header>

      <Container>
        {asset.type === 'folder' ? (
          <FolderView folderId={assetId} />
        ) : (
          <AssetViewerScreen asset={asset} />
        )}
      </Container>
    </>
  );
});

const Container = styled.div`
  margin: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const Crumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`;

const Parent = styled(Link)`
  color: #e3e4e7;

  &:hover {
    color: white;
  }
`;
