import React, { useContext, useLayoutEffect, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import styled from 'styled-components';

import { DndContextWrapper } from './components/dnd-context-wrapper';
import { Sidebar } from './components/sidebar';
import { Chart } from './components/chart';
import { ChartHeader } from './components/chart-header';
import { GanttChartContext } from './context/gantt-chart.context';
import { StripsIndicatorsLayer } from './components/strips-indicators-layer';
import { differenceInDays } from './utils/date-utils';

import type { GanttItem } from './types';

export type ChartWrapperProps = {
  items: GanttItem[];
};

export const ChartWrapper: React.FC<ChartWrapperProps> = ({ items }) => {
  const {
    datesRangeByMonths,
    dateTrackerLabelRef,
    sidebarRef,
    extendEndRange,
    extendStartRange,
    chartStartDate,
  } = useContext(GanttChartContext);

  const headerContainerRef = useRef<HTMLDivElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const { ref: setStartMarkerRef } = useIntersectionObserver({
    onChange: (isIntersecting: boolean, entry: IntersectionObserverEntry) => {
      if (isIntersecting) {
        if (!chartStartDate) {
          return;
        }
        const currentChartStartDate = new Date(chartStartDate);

        const newChartStartDate = new Date(chartStartDate);
        newChartStartDate.setMonth(newChartStartDate.getMonth() - 6);

        extendStartRange?.(6);

        const daysOffset = differenceInDays(newChartStartDate, currentChartStartDate);

        if (chartContainerRef?.current) {
          chartContainerRef.current.scrollLeft =
            chartContainerRef.current.scrollLeft + 48 * daysOffset;
        }
      }
    },
  });

  const { ref: setEndMarkerRef } = useIntersectionObserver({
    rootMargin: '0px 200px 0px 0px',
    onChange: (isIntersecting: boolean, entry: IntersectionObserverEntry) => {
      if (isIntersecting) {
        extendEndRange?.(6);
      }
    },
  });

  useLayoutEffect(() => {
    if (!datesRangeByMonths) {
      return;
    }

    const monthsKeys = Object.keys(datesRangeByMonths);

    const startThresholdIndex = 3;
    const endThresholdIndex = monthsKeys.length - 7; // 6 months + 1

    const startMonthMarker = monthsKeys[startThresholdIndex];
    const endMonthMarker = monthsKeys[endThresholdIndex];

    if (!startMonthMarker || !endMonthMarker) {
      return;
    }

    const firstStartDate = datesRangeByMonths[startMonthMarker]?.[0];
    const firstEndDate = datesRangeByMonths[endMonthMarker]?.[0];

    if (!firstStartDate || !firstEndDate) {
      return;
    }

    const {
      year: startYear,
      month: startMonth,
      day: startDay,
      isToday: startIsToday,
    } = firstStartDate;
    const startMarkerId = startIsToday ? 'today' : `${startDay}-${startMonth}-${startYear}`;
    const startMarker = document.getElementById(startMarkerId);

    const { year: endYear, month: endMonth, day: endDay, isToday: endIsToday } = firstEndDate;
    const endMarkerId = endIsToday ? 'today' : `${endDay}-${endMonth}-${endYear}`;
    const endMarker = document.getElementById(endMarkerId);

    if (!startMarker || !endMarker) {
      return;
    }

    setStartMarkerRef?.(startMarker);
    setEndMarkerRef?.(endMarker);
  }, [datesRangeByMonths, setEndMarkerRef, setStartMarkerRef]);

  const handleHeaderScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (chartContainerRef?.current?.scrollLeft !== headerContainerRef?.current?.scrollLeft) {
      if (headerContainerRef?.current && chartContainerRef?.current) {
        chartContainerRef.current.scrollLeft = headerContainerRef.current.scrollLeft;
      }
    }
  };

  const handleChartScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (headerContainerRef?.current && chartContainerRef?.current) {
      headerContainerRef.current.scrollLeft = chartContainerRef.current.scrollLeft;
    }
  };

  return (
    <Container>
      {datesRangeByMonths && (
        <>
          <HeaderContainer ref={headerContainerRef} onScroll={handleHeaderScroll}>
            <DateTrackerLabel ref={dateTrackerLabelRef} />

            <ChartHeader datesByMonths={datesRangeByMonths} />
          </HeaderContainer>

          <Wrapper>
            <Sidebar items={items} ref={sidebarRef} />

            <StripsIndicatorsLayer />

            <DndContextWrapper>
              <ChartContainer ref={chartContainerRef} onScroll={handleChartScroll}>
                <Chart />
              </ChartContainer>
            </DndContextWrapper>
          </Wrapper>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: fit-content;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  max-width: 100%;
  flex: 1;
  margin-top: -3rem;
  background-color: var(--color-surfaces-bg-elevation-2);
`;

const ChartContainer = styled.div`
  min-width: 0;
  min-height: 100%;
  width: 100%;
  margin-inline-start: -24rem;
  overflow-x: auto;
  overflow-y: clip;
`;

const HeaderContainer = styled.div`
  max-width: 100%;
  overflow: auto;
  position: sticky;
  top: 0;
  min-height: 9.4rem;
  z-index: 11;
  pointer-events: none;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DateTrackerLabel = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  background-color: black;
  color: var(--color-grayscale-white);
  text-align: center;
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  z-index: var(--layer-tooltip);
  width: auto;
  white-space: nowrap;
  opacity: 1;
`;
