import React from 'react';
import styled from 'styled-components';

interface InfoTextLineProps {
  value: string | number;
}

export const InfoTextLine: React.FC<InfoTextLineProps> = ({ value }) => (
  <InfoTextLineItem>{value}</InfoTextLineItem>
);

const InfoTextLineItem = styled.div`
  color: var(--color-grayscale-ghost);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
`;

export const BlueLine = styled.div`
  color: var(--color-primary-crayola);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.4px;
  margin-top: 0.8rem;
`;

export const Description = styled.p`
  padding-top: 0.8rem;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  height: 6.2rem;
  color: var(--card-subtitle-color);

  &[data-is-default='true'] {
    color: var(--card-subtitle-color-low);
  }
`;

const IconItem = styled.div`
  color: var(--white-default);
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  letter-spacing: -0.25px;
  font-weight: bold;
  align-items: center;
  display: flex;

  svg {
    margin-right: 0.4rem;
    fill: var(--color-primary);
  }
`;
