import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

interface EditableTextProps {
  onSubmit: (title: string) => void;
  onlyNumber?: boolean;
  defaultText?: string;
  maxLength?: number;
  max?: number;
  min?: number;
  className?: string;
  children: React.ReactNode;
}

export const EditableText: React.FC<EditableTextProps> = ({
  onSubmit,
  defaultText = '-',
  onlyNumber = false,
  maxLength,
  max,
  min,
  className,
  children,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [editModeState, setEditModeState] = useState(false);
  const [initialValue, setInitialValue] = useState(defaultText);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setInitialValue(textRef?.current?.innerText || defaultText);

    if (textRef && textRef.current) {
      textRef.current.contentEditable = 'true';
    }
  }, [defaultText]);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setEditModeState(true);

    if (textRef && textRef.current) {
      if (textRef.current.innerText === defaultText) {
        textRef.current.innerText = '';
      }

      textRef.current.contentEditable = 'true';
      textRef.current.focus();
    }
  };

  const handleBlur = async () => {
    setEditModeState(false);

    if (textRef && textRef.current) {
      if (maxLength && textRef.current.innerText.length > maxLength) {
        textRef.current.innerText = initialValue || defaultText;
        setErrorMessage('');
      } else if (textRef.current.innerText === '') {
        textRef.current.innerText = defaultText;
      } else {
        await onSubmit(textRef.current!.innerText);
        setInitialValue(textRef.current.innerText);
      }
    }
  };

  const onKeyUp = () => {
    if (maxLength && textRef.current!.innerText.length >= maxLength) {
      setErrorMessage(`Field must be ${maxLength} characters at most`);
    } else {
      setErrorMessage('');
    }
  };

  const onKeyDown = async (e: React.KeyboardEvent) => {
    e.stopPropagation();

    if (!editModeState) {
      return;
    }

    if (
      maxLength &&
      textRef.current!.innerText.length >= maxLength &&
      e.keyCode !== KeyboardKeys.BACKSPACE &&
      e.keyCode !== KeyboardKeys.ARROW_LEFT &&
      e.keyCode !== KeyboardKeys.ARROW_RIGHT
    ) {
      e.preventDefault();
    }

    if (onlyNumber) {
      if (
        !(e.keyCode === KeyboardKeys.BACKSPACE) &&
        !(e.keyCode === KeyboardKeys.ARROW_LEFT) &&
        !(e.keyCode === KeyboardKeys.ARROW_RIGHT) &&
        !(e.keyCode === KeyboardKeys.DOT) &&
        isNaN(Number(e.key))
      ) {
        e.preventDefault();
      }

      if (max !== undefined && Number(textRef.current!.innerText) > max) {
        e.preventDefault();
        textRef.current!.innerText = '00';
      }

      if (min !== undefined && Number(textRef.current!.innerText) < min) {
        e.preventDefault();
        textRef.current!.innerText = '00';
      }
    }

    if (e.key === KeyboardKeys.Escape) {
      Promise.resolve().then(() => {
        setEditModeState(false);

        if (textRef && textRef.current) {
          textRef.current.innerText = initialValue || defaultText;
          setErrorMessage('');
          textRef.current.contentEditable = 'false';
          textRef.current.contentEditable = 'true';
        }
      });
    }

    if (e.key === KeyboardKeys.Enter) {
      e.preventDefault();

      setEditModeState(false);

      if (textRef && textRef.current) {
        textRef.current.contentEditable = 'false';
        textRef.current.contentEditable = 'true';

        if (onlyNumber) {
          setInitialValue(parseFloat(textRef.current.innerText).toString());
        } else {
          setInitialValue(textRef.current.innerText);
        }

        if (maxLength && textRef.current.innerText.length > maxLength) {
          textRef.current.innerText = initialValue || defaultText;
          setErrorMessage('');
        } else if (textRef.current.innerText === '') {
          textRef.current.innerText = defaultText;
        } else {
          await onSubmit(textRef.current!.innerText);
        }
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = e.clipboardData?.getData('text');

    if (textRef.current) {
      // get current cursor position
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const start = range?.startOffset || 0;
      const end = range?.endOffset || 0;

      // replace only the selected text
      const text = textRef.current.innerText;
      textRef.current.innerText = text.slice(0, start) + data + text.slice(end);

      // set the cursor position after the pasted text
      range?.setStart(textRef.current.firstChild!, start + data.length);
      range?.setEnd(textRef.current.firstChild!, start + data.length);
    }
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child) && index === 0) {
      if (textRef.current) {
        return React.cloneElement(child, {
          // @ts-ignore
          ref: textRef,
          defaultValue: textRef.current.innerText !== defaultText,
        });
      } else {
        // @ts-ignore
        return React.cloneElement(child, { ref: textRef });
      }
    }

    return child;
  });

  return (
    <>
      <Editable
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onBlur={handleBlur}
        onClick={handleClick}
        onPaste={handlePaste}
        onMouseDown={(e) => e.stopPropagation()}
        className={className}
      >
        {childrenWithProps}
      </Editable>
      {!!errorMessage && <Error>{errorMessage}</Error>}
    </>
  );
};

const Editable = styled.div`
  cursor: text;
`;

const Error = styled.div`
  margin-top: 0.8rem;
  color: var(--color-error);
`;
