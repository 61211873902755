export const ShotSizeEnum = [
  // Old hidden values, kept for backward compatibility
  'XLS',
  'LS',
  'MLS',
  'MS',
  'BCU',

  // Recent reviewed Values
  'EST',
  'MA',
  'XW',
  'WID',
  'FUL',
  'MED',
  'MCU',
  'CU',
  'ECU',
  'MAC',
];
