import React from 'react';

export const CalendarIcon: React.FC = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 5.5H11.5V6.5C11.5 7.32843 10.8284 8 10 8C9.17157 8 8.5 7.32843 8.5 6.5V5.5H7C5.89543 5.5 5 6.39543 5 7.5V20.5C5 21.6046 5.89543 22.5 7 22.5H22C23.1046 22.5 24 21.6046 24 20.5V7.5C24 6.39543 23.1046 5.5 22 5.5H20.5V6.5C20.5 7.32843 19.8284 8 19 8C18.1716 8 17.5 7.32843 17.5 6.5V5.5ZM9.5 15C10.0523 15 10.5 14.5523 10.5 14C10.5 13.4477 10.0523 13 9.5 13C8.94772 13 8.5 13.4477 8.5 14C8.5 14.5523 8.94772 15 9.5 15ZM15.5 14C15.5 14.5523 15.0523 15 14.5 15C13.9477 15 13.5 14.5523 13.5 14C13.5 13.4477 13.9477 13 14.5 13C15.0523 13 15.5 13.4477 15.5 14ZM19.5 15C20.0523 15 20.5 14.5523 20.5 14C20.5 13.4477 20.0523 13 19.5 13C18.9477 13 18.5 13.4477 18.5 14C18.5 14.5523 18.9477 15 19.5 15ZM15.5 18C15.5 18.5523 15.0523 19 14.5 19C13.9477 19 13.5 18.5523 13.5 18C13.5 17.4477 13.9477 17 14.5 17C15.0523 17 15.5 17.4477 15.5 18ZM9.5 19C10.0523 19 10.5 18.5523 10.5 18C10.5 17.4477 10.0523 17 9.5 17C8.94772 17 8.5 17.4477 8.5 18C8.5 18.5523 8.94772 19 9.5 19ZM7.25 10C6.83579 10 6.5 10.3358 6.5 10.75C6.5 11.1642 6.83579 11.5 7.25 11.5H21.75C22.1642 11.5 22.5 11.1642 22.5 10.75C22.5 10.3358 22.1642 10 21.75 10H7.25Z"
      fill="currentColor"
    />
    <rect x="18" y="3.5" width="2" height="4" rx="1" fill="#7A8296" />
    <rect x="9" y="3.5" width="2" height="4" rx="1" fill="#7A8296" />
  </svg>
);
