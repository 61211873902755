import React, { useContext } from 'react';
import styled from 'styled-components';

import { CollapsibleItem } from '../../collapsible-item/collapsible-item';
import { StageHeader, StageNumber } from '../../../features/process/components/stage-header';
import { GanttChartContext } from '../context/gantt-chart.context';

import type { GanttChartProps } from '../gantt-chart';

type ItemProps = GanttChartProps['items'][number];

type SidebarItemRendererProps = {
  item: ItemProps;
  suffix: React.ReactNode;
  progressColor?: string;
};

export const SidebarItemRenderer: React.FC<SidebarItemRendererProps> = ({
  item,
  suffix,
  progressColor,
}) => {
  const { handleItemsCollapse, collapsibleStates, primaryColor, secondaryColor } =
    useContext(GanttChartContext);

  if (item.subItems) {
    const isCollapsed = !!collapsibleStates[item.id];

    return (
      <CollapsibleItem
        key={item.id}
        title={
          <CollapsibleContainer>
            <HeaderWrapper>
              <StageHeader
                name={item.name}
                suffix={suffix}
                total={item.total || 0}
                completed={item.completed || 0}
                progressColor={progressColor || primaryColor}
                isActive
              />
            </HeaderWrapper>
          </CollapsibleContainer>
        }
        triggerType="transparent-small"
        padding="none"
        onCollapseChange={(isOpen) => {
          handleItemsCollapse?.(item.id, !isOpen);
        }}
        defaultOpen={!isCollapsed}
        isTransparent
      >
        {item.subItems && (
          <SubList>
            {item.subItems?.map((subItem, index) => {
              if (subItem.subItems) {
                return (
                  <SubListWrapper key={subItem.id}>
                    <SidebarItemRenderer
                      item={subItem}
                      progressColor={secondaryColor}
                      suffix={
                        <StageNumber isActive>
                          {(index + 1).toString().padStart(2, '0')}
                        </StageNumber>
                      }
                    />
                  </SubListWrapper>
                );
              }

              return subItem.sideHeader ? (
                subItem.sideHeader
              ) : (
                <CollapsibleContainer key={subItem.id}>
                  <HeaderWrapper data-subItem="true">{subItem.name}</HeaderWrapper>
                </CollapsibleContainer>
              );
            })}
          </SubList>
        )}
      </CollapsibleItem>
    );
  }
};

SidebarItemRenderer.displayName = 'Sidebar';

const CollapsibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`;

const HeaderWrapper = styled.div`
  min-width: 0;
  flex: 1;
  max-height: 4rem;
  background-color: var(--color-surfaces-bg-elevation-1);
  display: flex;
  align-items: end;

  &[data-subItem='true'] {
    padding: 1.2rem 1.6rem;
    background-color: var(--color-grayscale-eerie-black);
    cursor: default;

    color: var(--color-grayscale-white, #fafafa);
    /* Body - Regular */
    font-size: 1.6rem;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }
  }

  & > div > div {
    &:first-child {
      padding: 0.9rem 1.6rem 0 4rem;
      margin-bottom: 0.9rem;

      h3 {
        font-weight: 400;
      }

      & > :last-child {
        display: none;
      }
    }

    &:last-child {
      & > div {
        border-radius: 0;
        background-color: var(--color-grayscale-arsenic);
        height: 0.4rem;
      }

      & * {
        border-radius: 0 0.4rem 0.4rem 0;
      }
    }
  }
`;

const SubList = styled.div`
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  user-select: none;
`;
const SubListWrapper = styled.div`
  padding-inline-start: 0.8rem;
`;
