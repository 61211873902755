export const CameraAngleEnum = [
  'aerial',
  'eye_level',
  'low_angle',
  'high_angle',
  'overhead',
  'shoulder_level',
  'hip_level',
  'knee_level',
  'ground_level',
];
