import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  CalendarIcon,
  DrawingPinIcon,
  FileIcon,
  GearIcon,
  GridIcon,
  Link2Icon,
} from '@radix-ui/react-icons';
import styled from 'styled-components';

import { uiStore } from '../../../core/stores/ui-store';
import { useCurrentProject } from '../../../hooks/use-current-project';
import { IconButton } from '../../../components/buttons';
import { ClapperIcon } from '../../../components/icons/clapper';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { MaximizeMenuIcon, MinimizeMenuIcon, TheatreMasksIcon } from '../../../components/icons';

type ProjectMenuProps = {
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onSelect?: () => void;
};

export const ProjectMenu: React.FC<ProjectMenuProps> = observer(
  ({ isOpen, setIsOpen, onSelect }) => {
    const navigate = useNavigate();

    const project = useCurrentProject();

    if (!project) {
      return null;
    }

    return (
      <Container data-expand={isOpen}>
        <MenuContent>
          <MenuHeader
            onClick={() => {
              navigate(`/space/${project.spaceId}/projects/${project._id}/`);
              onSelect?.();
            }}
          >
            <HeaderItem>
              <Tooltip text="Overview" position="right" disabled={isOpen}>
                <IconButton icon={<ClapperIcon />} />
              </Tooltip>

              <span>Overview</span>
            </HeaderItem>
          </MenuHeader>

          <Menu>
            <Tooltip text="Documents" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/documents`}
                data-is-active={uiStore.currentLocation === 'project.documents'}
              >
                <FileIcon />

                <span>Documents</span>
              </MenuItem>
            </Tooltip>

            {/*<MenuItemto={`/space/${project.spaceId}/projects/${project._id}/tasks`}
              onClick={onSelect} data-is-active={uiStore.currentLocation === 'project.tasks'}>
                <ClipboardIcon />
                Tasks
              </MenuItemto=>*/}

            <Tooltip text="Calendar" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/calendar`}
                data-is-active={uiStore.currentLocation === 'project.calendar'}
              >
                <CalendarIcon />

                <span>Calendar</span>
              </MenuItem>
            </Tooltip>

            <Tooltip text="Shared Links" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/share-links`}
                data-is-active={uiStore.currentLocation === 'project.share-links'}
              >
                <Link2Icon />

                <span>Shared Links</span>
              </MenuItem>
            </Tooltip>

            <Tooltip text="Settings" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/settings`}
                data-is-active={uiStore.currentLocation === 'project.settings'}
              >
                <GearIcon />

                <span>Settings</span>
              </MenuItem>
            </Tooltip>

            <Subtitle data-expand={isOpen}>Creation</Subtitle>

            <Tooltip text="Storyboards" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/storyboards`}
                data-is-active={uiStore.currentLocation === 'project.storyboards'}
              >
                <GridIcon />

                <span>Storyboards</span>
              </MenuItem>
            </Tooltip>

            <Subtitle data-expand={isOpen}>Resources</Subtitle>

            <Tooltip text="Locations" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/locations`}
                data-is-active={uiStore.currentLocation === 'project.locations'}
              >
                <DrawingPinIcon />

                <span>Locations</span>
              </MenuItem>
            </Tooltip>

            <Tooltip text="Characters" position="right" disabled={isOpen}>
              <MenuItem
                onClick={onSelect}
                to={`/space/${project.spaceId}/projects/${project._id}/characters`}
                data-is-active={uiStore.currentLocation === 'project.characters'}
              >
                <TheatreMasksIcon />

                <span>Characters</span>
              </MenuItem>
            </Tooltip>
          </Menu>
        </MenuContent>

        <div style={{ flex: 1 }} />

        <Footer>
          {setIsOpen && (
            <MenuToggleButton onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <MinimizeMenuIcon /> : <MaximizeMenuIcon />}
            </MenuToggleButton>
          )}
        </Footer>
      </Container>
    );
  },
);

const MenuHeader = styled.div`
  height: 4rem;
  min-width: 4rem;

  width: 100%;
  border-bottom: 1px var(--content-border-color) solid;
  color: #e3e4e7;
  font-size: 1.4rem;
  padding: 0.8rem;
  max-width: 100%;
  position: relative;
  gap: 0.4rem;
  cursor: pointer;
`;

const HeaderItem = styled.div`
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  gap: 0.4rem;

  button {
    background-color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary);
    }

    svg {
      stroke: var(--white-default);
      fill: var(--white-default);
      color: white;
    }
  }
`;

const Menu = styled.div`
  width: 100%;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const MenuItem = styled(Link)`
  color: #e3e4e7;
  font-size: 1.4rem;
  height: 3.2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  gap: 0.8rem;
  overflow: hidden;

  svg {
    min-width: 1.5rem;
  }

  span {
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
    background: var(--bg-elevation-2);
  }

  &[data-is-active='true'] {
    background: var(--bg-elevation-2);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 4rem;
  min-width: 4rem;
  position: relative;
  transition: all 0.2s ease;
  border-right: 1px var(--content-border-color) solid;

  &[data-expand='true'] {
    width: 20rem;
    min-width: 20rem;
  }
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-top: 0.8rem;
  font-size: 1.3rem;
  height: 2.6rem;
  color: transparent;
  display: flex;
  align-items: center;
  padding: 0.4rem;

  &:after {
    content: '';
    position: absolute;
    transform: translateX(-0.8rem);
    height: 1px;
    width: 100%;
    background: var(--color-surfaces-bg-elevation-2);
  }

  &[data-expand='true'] {
    color: #7a8296;

    &:after {
      display: none;
    }
  }
`;

const Footer = styled.div`
  padding: 0.4rem;
`;

const MenuToggleButton = styled.button`
  all: unset;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: var(--border-radius-small);
  background: var(--color-surfaces-app-background-0);
  color: var(--color-texts-low-contrast);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: var(--color-texts-high-contrast);
    background: var(--bg-elevation-2);
  }
`;
