import React from 'react';
import styled from 'styled-components';

import { SidebarItemRenderer } from './sidebar-item-renderer';

import type { GanttChartProps } from '../gantt-chart';

export const Sidebar = React.forwardRef<HTMLDivElement, GanttChartProps>(({ items }, ref) => (
  <Container ref={ref}>
    {items?.map((item) => (
      <SidebarItemRenderer
        key={item.id}
        item={item}
        suffix={item.headerSuffix || item.nameSuffix}
      />
    ))}
  </Container>
));

Sidebar.displayName = 'Sidebar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  z-index: 10;
  min-width: 24rem;
  max-width: 24rem;
  width: 24rem;
  padding-inline: 0.8rem;
  padding-top: 1.2rem;
  background-color: var(--color-surfaces-bg-elevation-1);
  gap: 0.4rem;

  & #collapsible-trigger {
    gap: unset;

    & > div:first-child {
      flex: 1;
    }

    & svg {
      position: absolute;
      left: 0.8rem;
    }
  }
`;
