import React from 'react';

import { GanttChartProvider } from './context/gantt-chart.provider';
import { ChartWrapper } from './chart-wrapper';

import type { GanttItem } from './types';

export type GanttChartProps = {
  items: GanttItem[];
  primaryColor?: string;
  secondaryColor?: string;
};

export const GanttChart: React.FC<GanttChartProps> = ({ items, primaryColor, secondaryColor }) => {
  return (
    <GanttChartProvider items={items} primaryColor={primaryColor} secondaryColor={secondaryColor}>
      <ChartWrapper items={items} />
    </GanttChartProvider>
  );
};
